'use client'

import { useEffect, useState } from 'react'
import Modal from '@/components/Modal'
import { ButtonColor, ButtonVariant, ModalType } from '@/lib/types'
import HubCreateForm from './HubCreateForm'

export default function HubCreate({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [currentStep, setCurrentStep] = useState(0)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  useEffect(() => {
    if (!isOpen) {
      setHasUnsavedChanges(false)
    }
  }, [isOpen])

  return (
    <Modal
      label={'Hub'}
      hideTrigger={true}
      modalLabelVariant={ButtonVariant.Large}
      modalLabelColor={ButtonColor.Black}
      modalType={ModalType.Default}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalHeader="Create Hub"
      disableKeydownSubmit={currentStep === 0}
      hasUnsavedChanges={hasUnsavedChanges}
    >
      <HubCreateForm
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setIsOpen={setIsOpen}
        setHasUnsavedChanges={setHasUnsavedChanges}
        isParentOpen={isOpen}
      />
    </Modal>
  )
}
