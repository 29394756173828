'use client'

import { useContext, useEffect, useState } from 'react'
import Form from '@/components/Form'
import Toast from '@/contexts/ToastContext'
import Update from '@/contexts/UpdateContext'
import Wallet from '@/contexts/WalletContext'
import { repostReleaseToHub } from '@/lib/release/repostReleaseToHub'
import { FormField, Hub, Release, SearchResultsRelease } from '@/lib/types'

type ReleaseRepostFormProps = {
  release: Release | SearchResultsRelease
  userHubs: Hub[]
  releaseHubs: string[]
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ReleaseRepostForm(props: ReleaseRepostFormProps) {
  const { release, userHubs, setIsOpen, releaseHubs } = props
  const { wallet } = useContext(Wallet.Context)
  const { addToast } = useContext(Toast.Context)
  const { update } = useContext(Update.Context)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const hubOptions = userHubs.map((hub) => {
    return {
      value: hub.publicKey,
      label: hub.data.displayName,
    }
  })

  const defaultHubOption =
    hubOptions.filter((hubOption) => !releaseHubs.includes(hubOption.value))[0]
      ?.value || ''

  const repostFields: FormField[] = [
    {
      name: 'hub',
      label: 'Hub',
      type: 'select',
      value: defaultHubOption,
      placeholder: 'Select a hub',
      required: true,
      inputProps: {
        inputSelectOptions: hubOptions,
        accentColor: 'text-blue-82',
      },
    },
  ]

  const [fields, setFields] = useState(repostFields)
  useEffect(() => {
    setFields((prevState) => {
      return prevState.map((field) => {
        if (field.name === 'hub') {
          return {
            ...field,
            inputProps: {
              ...field.inputProps,
              inputSelectOptions: hubOptions,
            },
          }
        }

        return field
      })
    })
  }, [userHubs])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFields((prevState) => {
      return prevState.map((field) => {
        if (field.name === name) {
          if (releaseHubs.includes(value)) {
            return {
              ...field,
              value,
              error: ['Already Reposted to this hub.'],
            }
          }

          return {
            ...field,
            value,
          }
        }

        return field
      })
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      setIsSubmitting(true)

      const response = await repostReleaseToHub(
        fields[0].value as string,
        release.publicKey,
        wallet,
      )

      update()

      if (response?.error) {
        throw new Error(response?.error)
      }

      setIsOpen(false)
      setIsSubmitting(false)
      addToast({
        message: `Release added to ${fields[0].label}`,
        variant: 'success',
      })
    } catch {
      setIsSubmitting(false)
      addToast({
        message: `Error adding release to to ${fields[0].label}`,
        variant: 'error',
      })
    }
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  return (
    <Form
      fields={fields}
      setFields={setFields}
      handleChange={handleChange}
      handleCancel={handleCancel}
      handleSubmit={(e) => handleSubmit(e)}
      buttonLabel="Repost"
      allowCancel={false}
      submittingLabel="Reposting"
      disableSubmit={isSubmitting}
      buttonStyleOverride="lg:pt-[32px] "
    />
  )
}
