'use client'

import clsx from 'clsx'
import React, { useRef, useState } from 'react'
import {
  ButtonColor,
  ButtonResponsiveness,
  ButtonVariant,
  Login,
  LoginOrPromiEvent,
} from '@/lib/types'
import Button from './tokens/Button'

interface OTPFormProps {
  setShowOtpUi: (showOtpUi: boolean) => void
  setIsEmailPending: (isEmailPending: boolean) => void
  emailAddress: string
  otpLogin: LoginOrPromiEvent | null
  setOtpLogin: (otpLogin: LoginOrPromiEvent | null) => void
}

export default function OTPForm({
  setShowOtpUi,
  setIsEmailPending,
  emailAddress,
  otpLogin,
  setOtpLogin,
}: OTPFormProps) {
  const [isOtpPending, setIsOtpPending] = useState(false)
  const [retries, setRetries] = useState(3)
  const [otp, setOtp] = useState('')

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  )

  const showSupplementalText = false
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null))

  const handlePaste = async (
    e: React.ClipboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData('text').replace(/\D/g, '')
    const newOtp = [...otp]
    for (let i = 0; i < Math.min(pastedData.length, 6 - index); i++) {
      newOtp[index + i] = pastedData.charAt(i)
    }

    setOtp(newOtp.join(''))
    const completedOtp = newOtp.join('')

    if (otpLogin) {
      if (completedOtp.length === 6) {
        await otpSubmit(e, completedOtp, otpLogin as Login)
      }
    }
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace' && index > 0 && !e.currentTarget.value) {
      const previousInput = inputRefs.current[index - 1]

      if (previousInput) {
        previousInput.focus()
      }
    }
  }

  const handleBackToEmailLogin = async (login: Login) => {
    setIsEmailPending(false)
    try {
      if (login) {
        setShowOtpUi(false)
        await login.emit('cancel')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleOTPFormChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 6)

    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus()
    }

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp]
      newOtp[index] = sanitizedValue.charAt(sanitizedValue.length - 1)
      const completedOtp = newOtp.join('')

      return completedOtp
    })
  }

  const otpSubmit = async (
    e: React.MouseEvent | React.FormEvent,
    value: string,
    login: Login,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOtpPending(true)

    const trimmedPasscode = value.replaceAll(/\s/g, '')
    await login.emit('verify-email-otp', trimmedPasscode)

    login.on('invalid-email-otp', () => {
      const updatedRetries = retries - 1
      setRetries(updatedRetries)

      if (retries === 0) {
        setErrorMessage('No more retries. Please try again later.')
        login.emit('cancel', () => {
          setShowOtpUi(false)
          setOtpLogin(null)
          setRetries(3)
        })
      } else {
        setErrorMessage(
          `Incorrect code. ${retries - 1} ${
            retries === 1 ? 'retry' : 'retries'
          } left.`,
        )
        setIsOtpPending(false)
      }
    })
    login.on('email-otp-verified', () => {
      setRetries(3)
    })
  }

  return (
    <div className="relative mt-32 flex flex-col">
      <div>
        <p
          className={clsx('ui-1 text-opacity-20', isOtpPending && 'opacity-20')}
        >
          Enter one-time passcode
        </p>
        <div className="mt-8 grid grid-cols-6 gap-x-8 md:gap-x-12">
          {Array.from({ length: 6 }, (_, index) => (
            <input
              key={index}
              inputMode="numeric"
              type="number"
              className={clsx(
                'body-1 pw-input border-box aspect-h-1 aspect-w-1 h-[52px] w-[52px] !appearance-none !rounded-none border-1 border-black py-10 text-center text-[32px] focus:outline-none disabled:opacity-20 sm:h-[72px] sm:w-[72px]',
                isOtpPending && 'opacity-20',
              )}
              maxLength={1}
              value={otp[index] || ''}
              disabled={retries === 0 || isOtpPending}
              onChange={(e) => handleOTPFormChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onFocus={(e) => e.target.select()}
              onPaste={(e) => handlePaste(e, index)}
              ref={(input) =>
                (inputRefs.current[index] = input as HTMLInputElement)
              }
            />
          ))}
        </div>
        <div className=" mt-8">
          {errorMessage && (
            <p className={clsx('caption-1', isOtpPending && 'opacity-20')}>
              {errorMessage}
            </p>
          )}
          <p
            className={clsx(
              'caption-1 text-grey-45',
              isOtpPending && 'opacity-20',
            )}
          >
            Code sent to {emailAddress}
          </p>
        </div>
      </div>

      <div className="mt-60">
        <Button
          responsiveness={ButtonResponsiveness.Full}
          color={ButtonColor.Solid}
          variant={ButtonVariant.Large}
          type="submit"
          disabled={otp.length !== 6 || retries === 0}
          label={'Log in'}
          submittingLabel={'Logging in'}
          onClick={(e) => otpSubmit(e, otp, otpLogin as Login)}
          isSubmitting={isOtpPending}
        />

        <div className="mt-12">
          <Button
            responsiveness={ButtonResponsiveness.Full}
            color={ButtonColor.Blue}
            variant={ButtonVariant.Large}
            disabled={isOtpPending}
            onClick={() => handleBackToEmailLogin(otpLogin as Login)}
            label="Go back"
            style={{ backgroundColor: 'black' }}
          />
          {showSupplementalText && (
            <p
              className={clsx(
                'mt-8 text-12 text-grey-45 ',
                isOtpPending && 'opacity-20',
              )}
            >
              When you create an account on Nina, a wallet will be generated on
              your behalf so you can collect Releases and lorem ipsum dolor sit.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
