'use client'

import Image, { ImageProps } from 'next/image'
import React, { useState } from 'react'

const ImageWithArweaveFallbackClient = (props: ImageProps) => {
  const { src, ...rest } = props
  const [imgSrc, setImgSrc] = useState(src)

  return (
    <Image
      {...rest}
      src={imgSrc}
      onError={() => {
        setImgSrc(`${src}?fallback=true`)
      }}
    />
  )
}

export default ImageWithArweaveFallbackClient
