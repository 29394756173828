export const formatHandleString = (inputString: string) => {
  if (!inputString) return ''

  return inputString.toLowerCase().replaceAll(/[^a-z0-9]/g, '-')
}

export const formatEmailString = (inputString: string) => {
  if (!inputString) return ''

  return inputString.replace(/\s+/g, '').toLowerCase()
}
