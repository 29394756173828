'use client'

import { useContext, useEffect, useState } from 'react'
import Modal from '@/components/Modal'
import Button from '@/components/tokens/Button'
import { WalletContextValues } from '@/contexts/WalletContext'
import Wallet from '@/contexts/WalletContext'
import { fetchHubsForAccount } from '@/lib/hub/fetchHubsForAccount'
import { fetchHubsForRelease } from '@/lib/hub/fetchHubsForRelease'
import { PublicKeyString, Release } from '@/lib/types'
import {
  ButtonColor,
  ButtonResponsiveness,
  ButtonVariant,
  Hub,
} from '@/lib/types'
import HubCreate from './HubCreate'
import Login from './Login'
import ReleaseRepostForm from './ReleaseRepostForm'

export default function ReleaseRepostModal({
  release,
  forceOpen,
  hideTrigger,
  variant = 'default',
}: {
  release: Release
  forceOpen?: boolean
  hideTrigger?: boolean
  variant?: string
}) {
  const [isOpen, setIsOpen] = useState(forceOpen || false)
  const [hubModalOpen, setHubModalOpen] = useState(false)
  const { wallet } = useContext(Wallet.Context) as WalletContextValues
  const [userHubs, setUserHubs] = useState<Hub[]>([])
  const [releaseHubs, setReleaseHubs] = useState<PublicKeyString[]>([])
  const [fetched, setFetched] = useState(false)
  useEffect(() => {
    const handleFetchUserHubs = async () => {
      const hubsData = await fetchHubsForAccount({
        publicKey: wallet.publicKey?.toBase58() as string,
        pagination: {
          limit: 1000,
          offset: 0,
        },
        withAccountData: false,
      })

      const releaseHubs = await fetchHubsForRelease(
        release.publicKey,
        {
          limit: 1000,
          offset: 0,
        },
        false,
      )

      setReleaseHubs(releaseHubs.hubs.map((hub) => hub.publicKey))
      setUserHubs(hubsData.hubs)

      if (releaseHubs && userHubs) {
        setFetched(true)
      }
    }

    if (wallet.publicKey) {
      handleFetchUserHubs()
    }
  }, [wallet.publicKey])

  return (
    <div>
      {!hideTrigger && (
        <div className="md:hover:opacity-70">
          {variant === 'default' && (
            <Button
              icon="repost"
              variant={ButtonVariant.Square}
              color={ButtonColor.Black}
              onClick={() => setIsOpen(true)}
            />
          )}
          {variant === 'drawer' && (
            <Button
              icon="repost"
              variant={ButtonVariant.SquareBorderWhite}
              color={ButtonColor.Solid}
              onClick={() => setIsOpen(true)}
            />
          )}
        </div>
      )}

      <Modal
        modalLabelVariant={ButtonVariant.Large}
        modalLabelTextColor={ButtonColor.Black}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hideTrigger={true}
        modalHeader={!wallet.publicKey ? 'Sign In' : 'Repost to hub'}
      >
        {(!wallet.publicKey || !fetched) && (
          <div>
            <Login
              loginPromptString="Create an account or sign in to repost this release."
              variant="loginPrompt"
            />
          </div>
        )}

        {userHubs.length === 0 && wallet.publicKey && fetched && (
          <div className="mt-60">
            <p className="display-1 mb-60">
              Create a hub to start reposting releases.
            </p>

            <Button
              onClick={() => setHubModalOpen(true)}
              label="Create Hub"
              variant={ButtonVariant.Large}
              color={ButtonColor.Grey}
              textColor={ButtonColor.Grey}
              responsiveness={ButtonResponsiveness.Full}
            />

            <HubCreate isOpen={hubModalOpen} setIsOpen={setHubModalOpen} />
          </div>
        )}

        {wallet.publicKey && userHubs.length > 0 && fetched && (
          <ReleaseRepostForm
            release={release}
            userHubs={userHubs}
            releaseHubs={releaseHubs}
            setIsOpen={setIsOpen}
          />
        )}
      </Modal>
    </div>
  )
}
