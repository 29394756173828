'use client'

import { WalletName, WalletReadyState } from '@solana/wallet-adapter-base'
import clsx from 'clsx'
import { useRouter } from 'next/navigation'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Toast from '@/contexts/ToastContext'
import Wallet from '@/contexts/WalletContext'
import {
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  LoginOrPromiEvent,
} from '@/lib/types'
import * as pixel from '@/lib/utils/fpixel'
import { validateEmail } from '@/lib/utils/validateEmail'
import OTPForm from './OTPForm'
import Button from './tokens/Button'
import Input from './tokens/Input'

type LoginProps = {
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  loginPromptString?: string
  loginModalLabelPromptString?: string
  redirectOnLogin?: string
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
  variant?: string
}

export default function Login({
  setIsOpen,
  loginPromptString,
  loginModalLabelPromptString,
  redirectOnLogin,
  setMenuOpen,
  variant,
}: LoginProps) {
  const router = useRouter()
  const [emailAddress, setEmailAddress] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [showOtpUi, setShowOtpUi] = useState(false)
  const [isEmailPending, setIsEmailPending] = useState(false)
  const [otpLogin, setOtpLogin] = useState<LoginOrPromiEvent | null>(null)
  const { connectMagicWallet, setupMagic } = useContext(Wallet.Context)
  const { walletExtension, wallet } = useContext(Wallet.Context)
  const { addToast } = useContext(Toast.Context)

  const supportedWallets = useMemo(() => {
    if (walletExtension) {
      return walletExtension.wallets.filter(
        (wallet) => wallet.readyState !== WalletReadyState.Unsupported,
      )
    }

    return []
  }, [walletExtension])

  useEffect(() => {
    pixel.event('login_modal_open')
  }, [])

  const handleEmailLoginFormChange = (e: React.ChangeEvent<HTMLElement>) => {
    const { value } = e.target as HTMLInputElement
    setEmailAddress(value)

    if (validateEmail(value)) {
      setIsValidEmail(true)
    }
  }

  const handleWalletSelectEvent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    walletName: WalletName<string> | null,
  ) => {
    event.preventDefault()
    localStorage.setItem('nina_magic_wallet', 'false')
    wallet.select(walletName)

    if (setIsOpen) {
      setIsOpen(false)
    }
  }

  const handleClose = () => {
    if (setIsOpen) {
      setIsOpen(false)
    }

    if (setMenuOpen) {
      setMenuOpen(false)
    }

    setEmailAddress('')
    setIsValidEmail(false)
    setShowOtpUi(false)
    setIsEmailPending(false)
    addToast({
      message: 'Successfully logged in',
      variant: 'success',
    })
  }

  const handleAutoClose = () => {
    if (redirectOnLogin && setMenuOpen) {
      setMenuOpen(false)
      router.push(redirectOnLogin)
    }

    handleClose()

    return
  }

  const handleSubmit = async (
    e: React.MouseEvent | React.FormEvent,
    email: string,
  ) => {
    e.stopPropagation()
    e.preventDefault()
    setIsEmailPending(true)
    const magic = await setupMagic()
    pixel.event('login_submit')

    try {
      localStorage.setItem('nina_magic_wallet', 'true')

      const otp = magic.auth.loginWithEmailOTP({
        email,
        showUI: false,
      })

      otp
        .on('email-otp-sent', () => {
          setOtpLogin(otp as LoginOrPromiEvent)
          setShowOtpUi(true)
          setIsEmailPending(false)
        })
        .on('done', () => {
          connectMagicWallet(magic)
        })
        .on('settled', () => {
          setOtpLogin(null)
        })
        .then(() => {
          // if user is logging in from a create / action cta, we want to bypass the autoclose function but confirm the successful login to the user
          if (variant === 'loginPrompt') {
            addToast({
              message: 'Successfully logged in',
              variant: 'success',
            })
          } else {
            // handles the necessary cleanup actions if user is logging in from the login cta
            // clean up includes closing the modal, setting form to initial state and redirecting the user to the appropriate page
            handleAutoClose()
          }
        })
        .catch((err: Error) => {
          addToast({
            message: 'Error logging in',
            variant: 'error',
          })
          console.error('magic login error: ', err)
        })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="flex flex-col md:mt-0">
      {showOtpUi ? (
        <OTPForm
          setShowOtpUi={setShowOtpUi}
          setIsEmailPending={setIsEmailPending}
          emailAddress={emailAddress}
          otpLogin={otpLogin}
          setOtpLogin={setOtpLogin}
        />
      ) : (
        <>
          {!loginModalLabelPromptString && loginPromptString && (
            <p
              className={clsx(
                'display-1 mb-[20px] mt-[40px] ',
                isEmailPending && 'opacity-20',
              )}
            >
              {loginPromptString}
            </p>
          )}
          {loginModalLabelPromptString && !loginPromptString && (
            <p
              className={clsx(
                'display-1 mb-[20px] mt-[40px] ',
                isEmailPending && 'opacity-20',
              )}
            >
              {loginModalLabelPromptString}
            </p>
          )}
          <form onSubmit={(e) => handleSubmit(e, emailAddress)}>
            <div className="mt-40 flex flex-col md:mt-40">
              <Input
                placeholder="Enter your email address"
                name={'email'}
                type={'text'}
                required={false}
                label="Email Address"
                value={emailAddress}
                disabled={isEmailPending}
                handleChange={handleEmailLoginFormChange}
                styleOverride={clsx(isEmailPending && 'opacity-20')}
              />

              <div className="mt-8">
                <Button
                  responsiveness={ButtonResponsiveness.Full}
                  color={ButtonColor.Solid}
                  variant={ButtonVariant.Large}
                  font={ButtonFont.Body1}
                  disabled={!isValidEmail || !emailAddress}
                  label={'Send me a one-time passcode'}
                  onClick={(e) => handleSubmit(e, emailAddress)}
                  type="submit"
                  isSubmitting={isEmailPending}
                />
              </div>
            </div>
          </form>
          {supportedWallets.length > 0 && (
            <div className="mt-60">
              <p className={clsx('ui-1', isEmailPending && 'opacity-20')}>
                Or connect wallet
              </p>
              <div className="mt-8">
                <div
                  className={clsx(
                    supportedWallets.length > 1 && 'grid grid-cols-2 gap-8',
                  )}
                >
                  {supportedWallets.map((wallet) => (
                    <Button
                      key={wallet.adapter.name}
                      color={ButtonColor.Grey}
                      textColor={ButtonColor.Grey}
                      variant={ButtonVariant.Large}
                      font={ButtonFont.Body1}
                      label={wallet.adapter.name}
                      disabled={isEmailPending}
                      onClick={(event) =>
                        handleWalletSelectEvent(event, wallet.adapter.name)
                      }
                      styleOverride={clsx(
                        supportedWallets.length === 1 && 'w-full',
                      )}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
