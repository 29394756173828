import Nina from '@nina-protocol/js-sdk'
import { WalletContextState } from '@solana/wallet-adapter-react'
import axios from 'axios'
import { PublicKeyString, TxResponse } from '../types'
import initSdk from '../utils/initSdk'

export const repostReleaseToHub = async (
  hubPublicKey: PublicKeyString,
  releasePublicKey: PublicKeyString,
  wallet: WalletContextState,
) => {
  try {
    await initSdk(wallet)

    const tx = await Nina.Hub.hubAddRelease(
      hubPublicKey,
      releasePublicKey,
      undefined,
      true,
    )

    const response: TxResponse = await axios.post(
      `${process.env.NINA_FILE_SERVICE_ENDPOINT}/tx`,
      tx,
    )

    return response.data
  } catch (err) {
    console.error(err)

    return {
      error: err,
    }
  }
}
