import { Release, SearchResultsRelease } from '../types'

export default function formatReleaseData(
  release: Release | SearchResultsRelease,
) {
  const isSearchResult =
    'artist' in release && 'title' in release && 'image' in release

  const releaseLinkString = isSearchResult
    ? release.publicKey
    : release.slug || release.publicKey

  const searchResultDate = new Date(
    release.accountData?.release?.releaseDatetime || '',
  )

  const releaseImageDateString = isSearchResult
    ? searchResultDate
    : release?.datetime

  const releaseImageString = isSearchResult
    ? release.image
    : release.metadata?.image

  const releaseTitleString = isSearchResult
    ? `${release.artist} - ${release.title}`
    : release.metadata?.name

  return {
    isSearchResult,
    releaseLinkString,
    searchResultDate,
    releaseImageString,
    releaseImageDateString,
    releaseTitleString,
  }
}
