'use client'

import { useContext, useState } from 'react'
import AudioContext from '@/contexts/AudioContext'
import { ListBoxView, PlayType, Release } from '@/lib/types'
import ReleaseRepostModal from './ReleaseRepostModal'
import Ellipsis from './tokens/Ellipsis'

export default function ReleaseListBox(props: {
  release: Release
  view: ListBoxView
  variant?: string
  index?: number
  isTrack?: boolean
}) {
  const { release, view, variant, index, isTrack = false } = props
  const [showRepost, setShowRepost] = useState(false)

  const { addReleaseToQueue, addTrackToQueue, playRelease } = useContext(
    AudioContext.Context,
  )

  const releaseOptions = {
    playNext: {
      label: 'Play next',
      type: 'playNext',
      callback: (release: Release) => {
        addReleaseToQueue(release, PlayType.Next)
      },
    },
    playLater: {
      label: 'Play later',
      type: 'playLater',
      callback: (release: Release) => {
        addReleaseToQueue(release, PlayType.Later)
      },
    },
    divider: {
      label: '',
      type: 'divider',
      callback: () => null,
    },
    repostRelase: {
      label: 'Repost',
      type: 'repostRelease',
      callback: () => {
        setShowRepost(true)
      },
    },
  }

  const trackOptions = {
    playNow: {
      label: 'Play now',
      type: 'playNow',
      callback: (release: Release) => {
        playRelease(release, index)
      },
    },
    playNext: {
      label: 'Play next',
      type: 'playNext',
      callback: (release: Release, index: number) => {
        addTrackToQueue(release, index + 1, PlayType.Next)
      },
    },
    playLater: {
      label: 'Play later',
      type: 'playLater',
      callback: (release: Release, index: number) => {
        addTrackToQueue(release, index + 1, PlayType.Later)
      },
    },
  }

  if (variant === 'header') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete releaseOptions?.divider
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete releaseOptions?.repostRelase
  }

  const options = () => {
    if (isTrack || view === ListBoxView.List) {
      return trackOptions
    } else {
      return releaseOptions
    }
  }

  return (
    <>
      <Ellipsis
        options={options()}
        value={release}
        view={view}
        variant={variant}
        parentIndex={index}
        align={view === ListBoxView.List ? 'left' : undefined}
      />

      {showRepost && (
        <ReleaseRepostModal
          release={release}
          forceOpen={true}
          hideTrigger={true}
        />
      )}
    </>
  )
}
