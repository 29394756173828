'use client'

import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import Wallet from '@/contexts/WalletContext'
import { Release, SearchResultsRelease, View } from '@/lib/types'

type CollectedFlagProps = {
  release: Release | SearchResultsRelease
  view?: View
}

export default function CollectedFlag({ release, view }: CollectedFlagProps) {
  const { userHasRelease, collection } = useContext(Wallet.Context)
  const [isCollector, setIsCollector] = useState(false)
  useEffect(() => {
    if (collection) {
      setIsCollector(userHasRelease(release.publicKey))
    }
  }, [collection])

  if (!isCollector) return null

  return (
    <div
      className={clsx(
        view === View.Grid && 'mt-4 ',
        view === View.List && 'mt-4',
        view === View.Header && 'mt-0',
        'flex cursor-default flex-row items-center',
      )}
    >
      <p className="ui-1 text-grey-45">Collected</p>
    </div>
  )
}
