import { FormField } from '@/lib/types'

/* eslint-disable  @typescript-eslint/no-explicit-any */

export const validateField = (field: FormField, e: any) => {
  if (field.inputProps?.validate) {
    const fieldValidation = field.inputProps.validate(e.target.value)

    if (!fieldValidation) {
      e.target.scrollIntoView()
    }

    return fieldValidation === true ? undefined : [fieldValidation]
  }
}
