import { ImageCropFile } from '../types'

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image() as HTMLImageElement
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

export default async function getCroppedImage(
  imageSrc: string | ImageCropFile,
  pixelCrop: { width: number; height: number; x: number; y: number },
  filename?: string | undefined,
  fileType?: string | undefined,
) {
  const rotation = 0
  const flip = { horizontal: false, vertical: false }
  const image = (await createImage(imageSrc as string)) as HTMLImageElement
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  )

  // set canvas size to match the rotated dimensions of the image
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  ctx.drawImage(image, 0, 0)

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
  )

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.putImageData(data, 0, 0)

  return new Promise((resolve) => {
    const imageType = fileType || 'image/jpeg'

    const imageTypeExtension =
      imageType.split('/')[1].toLowerCase() === 'jpg' ||
      imageType.split('/')[1].toLowerCase() === 'jpeg'
        ? 'jpg'
        : 'png'

    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File(
          [blob],
          `${filename || 'fileName'}.${imageTypeExtension}`,
          {
            type: imageType,
          },
        )

        resolve({
          file,
          preview: URL.createObjectURL(file),
        })
      }
    }, imageType)
  })
}
